<template>
  <div :class="$style.settings">
    <div :class="$style.wrapper">
      <el-button type="primary" @click="submitForm('form')">
        Сохранить
      </el-button>
    </div>
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-position="right"
      label-width="120px"
    >
      <div :class="$style.blockTitle">Футер</div>
      <el-form-item label="Телефон" prop="footer.phone">
        <el-input v-model="form.footer.phone" data-test-id="footer_phone" />
      </el-form-item>
      <el-form-item label="Email" prop="footer.email">
        <el-input v-model="form.footer.email" data-test-id="footer_email" />
      </el-form-item>
      <div :class="$style.blockTitle">Форма обратной связи</div>
      <el-form-item label="Заголовок" prop="feedbackForm.title">
        <el-input
          type="textarea"
          v-model="form.feedbackForm.title"
          data-test-id="feedbackForm_title"
        />
      </el-form-item>

      <el-form-item label="Описание" prop="feedbackForm.description">
        <el-input
          type="textarea"
          v-model="form.feedbackForm.description"
          data-test-id="feedbackForm_description"
        />
      </el-form-item>

      <el-form-item
        label="Подзаголовок"
        type="textarea"
        prop="feedbackForm.additionalFeedbackTitle"
      >
        <el-input
          v-model="form.feedbackForm.additionalFeedbackTitle"
          data-test-id="feedbackForm_additionalFeedbackTitle"
        />
      </el-form-item>

      <el-form-item label="Способы связи" prop="customFeedbackOptions">
        <el-button
          style="margin-bottom: 1rem"
          type="primary"
          icon="el-icon-plus"
          @click="add()"
        >
          Добавить
        </el-button>
        <div
          v-for="(item, index) in form.customFeedbackOptions"
          :key="index"
          :class="$style.solutionContent"
        >
          <el-select
            v-model="item.selectOption"
            placeholder="Выберите"
            :class="$style.select"
          >
            <el-option
              v-for="option in feedbackFormOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
          <el-form-item
            label="Заголовок"
            label-width="80px"
            style="width: 100%"
          >
            <el-input v-model="item.text" />
          </el-form-item>
          <el-form-item label="Ссылка" label-width="80px" style="width: 100%">
            <el-input v-model="item.link" />
          </el-form-item>
          <el-button icon="el-icon-delete-solid" @click="remove(index)" />
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
export default {
  data() {
    return {
      form: {
        footer: {
          phone: '',
          email: ''
        },
        feedbackForm: {
          title: '',
          description: '',
          additionalFeedbackTitle: '',
          additionalFeedbackOptions: []
        },
        customFeedbackOptions: []
      },

      feedbackFormOptions: [
        { value: '', label: 'Ссылка' },
        { value: 'tel:', label: 'Телефон' },
        { value: 'mailto:', label: 'Почта' }
      ],
      rules: {
        footer: {
          phone: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          email: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        },
        feedbackForm: {
          title: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          description: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          additionalFeedbackTitle: {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        },
        customFeedbackOptions: {
          type: 'array',
          required: true,
          message: 'Пожалуйста, заполните поле',
          trigger: 'blur'
        }
      }
    }
  },
  created() {
    this.getPage('site-settings')
  },
  methods: {
    async getPage(slug) {
      const loading = this.$loading({
        lock: true
      })
      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get(slug, 'sellers')
      loading.close()
      if (error) return
      this.form = { ...this.form, ...value.content }
      if (!this.form?.footer) {
        this.form.footer = {}
      }
      if (!this.form?.feedbackForm) {
        this.form.feedbackForm = {}
      }
      if (this.form?.feedbackForm?.additionalFeedbackOptions) {
        this.form.customFeedbackOptions =
          this.form.feedbackForm.additionalFeedbackOptions.map((item) =>
            this.parsingFeedbackOptions(item)
          )
      }
    },
    add() {
      this.form.customFeedbackOptions.push({
        selectOption: '',
        text: '',
        link: ''
      })
    },
    remove(index) {
      this.form.customFeedbackOptions.splice(index, 1)
    },
    parsingFeedbackOptions(options) {
      let selectOption = ''
      let link = options.link
      this.feedbackFormOptions.forEach((item) => {
        if (options.link.includes(item.value)) {
          selectOption = item.value
          link = options.link.replace(selectOption, '')
        }
      })
      return {
        selectOption,
        link,
        text: options.text
      }
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })
          this.form.feedbackForm.additionalFeedbackOptions =
            this.form.customFeedbackOptions.map((item) => ({
              text: item.text,
              link: item.selectOption + item.link
            }))
          const result = await delivery.ContentServiceCore.PagesActions.update(
            'site-settings',
            'sellers',
            this.form
          )
          if (result.error) {
            alert('Ошибка обновлена страницы: ' + result.error)
          } else {
            alert('Старница успешно обновлена')
          }
          loading.close()
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.settings {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .blockTitle {
    @include blockTitle;
  }
  .solutionContent {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    .select {
      max-width: 7rem;
      width: 100%;
    }
  }
}
</style>
